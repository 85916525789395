import React from "react"
import Seo from "../seo"
import combos from "./combos"
import Banner from "../Banner"
import Layout from "../Layout"
import Heading from "../Heading"
import BaseColumn from "../MainColumn"
import styled from "styled-components"
import { useStaticQuery, graphql, Link } from "gatsby"
import { prop, palette, theme, ifProp } from "styled-tools"

const MainColumn = styled(BaseColumn)`
  padding: 6.4rem 0;
`

const Combos = styled.div`
  display: flex;
  margin-top: 6.4rem;
  align-items: center;
  justify-content: space-between;
`

const Combo = styled.article`
  margin: 0 2.4rem;
  border-radius: 10px;
  order: ${prop("order")};
  flex: ${ifProp({ order: 1 }, 1.5, 1)};
  background-color: ${palette("white")};
  box-shadow: ${theme("boxShadow.spread")};
  max-width: ${ifProp("highlight", "40rem")};
`

const ComboHeader = styled.div`
  padding: 3.2rem;
  text-align: center;
  background-color: ${ifProp("highlight", palette("success", 1), palette("info"))};
`

const ComboTitle = styled.h1`
  font-size: ${ifProp("highlight", "3.2rem", "2.4rem")};
  color: ${ifProp("highlight", palette("success", 2), palette("info", 1))};
`

const ComboHighlight = styled.span`
  font-size: 1.4rem;
  margin-top: 1.6rem;
  border-radius: 6px;
  padding: 0.4rem 1rem;
  display: inline-block;
  color: ${palette("grey", 1)};
  background-color: ${palette("white")};
  box-shadow: ${theme("boxShadow.main")};
  font-weight: ${theme("fontWeight.bold")};
  font-family: ${theme("fontFamily.secondary")};
`

const ComboList = styled.ul`
  padding: 3.2rem;
  text-align: center;
`

const ComboListItem = styled.li`
  margin-bottom: 0.8rem;
  color: ${palette("grey", 1)};
  font-family: ${theme("fontFamily.secondary")};
`

const ComboCallback = styled.div`
  padding: 2.4rem;
  background-color: ${ifProp("highlight", palette("success", 1), palette("info"))};
`

const ComboCallbackLink = styled(Link)`
  display: block;
  cursor: pointer;
  padding: 1.6rem;
  text-align: center;
  border-radius: 6px;
  color: ${palette("white")};
  box-shadow: ${theme("boxShadow.main")};
  transition: transform ${theme("times.3")};
  background-color: ${ifProp("highlight", palette("success", 2), palette("info", 1))};

  &:hover {
    transform: translateY(-3px);
  }
`

function Products() {
  const { file } = useStaticQuery(
    graphql`
      query {
        file(relativePath: { eq: "products-banner-image.png" }) {
          childImageSharp {
            fluid(maxWidth: 1800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  )

  return (
    <Layout>
      <Seo title="Nossos produtos" />
      <Banner file={file} />
      <div id="content">
        <MainColumn>
          <Heading
            title="Nossos Produtos"
            description="Confira todos nossos combos"
          />
          <Combos>
            {combos.map((combo, index) => (
              <Combo key={combo.title} order={combo.order} highlight={combo.highlight}>
                <ComboHeader highlight={combo.highlight}>
                  <ComboTitle highlight={combo.highlight}>{combo.title}</ComboTitle>
                  {combo.highlight && <ComboHighlight>Mais vendido</ComboHighlight>}
                </ComboHeader>
                <ComboList>
                  {combo.content.map(item => (
                    <ComboListItem key={item}>
                      {item}
                    </ComboListItem>    
                  ))}
                </ComboList>
                <ComboCallback highlight={combo.highlight}>
                  <ComboCallbackLink to={`/comprar?order=${combo.number}`} highlight={combo.highlight}>
                    Comprar agora
                  </ComboCallbackLink>
                </ComboCallback>
              </Combo>
            ))}
          </Combos>
        </MainColumn>
      </div>
    </Layout>
  )
}

export default Products
