export default [
  {
    order: 1,
    number: 1,
    title: "Alimentação Básica",
    highlight: true,
    content: [
      "1 Arroz Camil (5kg)",
      "1 Feijão Carioca (1kg)",
      "1 Feijão preto (1kg)",
      "1 Achocolatado em pó (500g)",
      "1 macarrão parafuso (500g)",
      "1 macarrão Espaguete (500)",
      "1 Açúcar Cristal (5 kg)",
      "2 Óleo de Soja (900ml)",
      "2 Molho de Tomate (340g)",
      "1 Vidro de Maionese Arisco (300g)",
      "5 Macarrão Istantâneo (80g)",
      "1 Café Pilão (500g)",
      "2 Farinha de Trigo (170g)",
      "2 Farinha de Fuba (170g)",
      "2 Farinha de Mandioca (170g)",
      "2 Latas Milho verde (200g)",
      "3 Sucos em Pó (25g)",
      "1 Mistura para Bolo (450g)",
      "1 Vinagre de Maça (750g)",
      "1 Tempero (300g)",
      "1 Caldo knorr (114g)"
    ]
  },
  {
    order: 0,
    number: 2,
    title: "Limpeza & Higiene",
    content: [
      "1 Sabão de Coco Barra (200g)",
      "1 Lã de Aço (60g)",
      "3 Detergente (500ml)",
      "1 Toalha de Papel folha dupla",
      "2 Cremes Dental Colgate (90g)",
      "1 Sabão em Pó Omo (2Kl)",
      "1 Pacote Papel Higiênico (12un)",
      "1 Amaciante Roupas (500ml)",
      "2 Esponja multiuso",
      "2 Aguá Sanitária (1L)",
      "1 Alvejante sem Cloro (1L)",
      "2 Desinfetantes (500ml)",
      "1 Limpador Perfumado (500ml)",
      "1 Pacote de Sabonetes (5un)",
      "1 Guardanapo de papel pequeno",
      "1 Shampoo para cabelos",
      "1 Condicionador para cabelos"
    ]
  },
  {
    order: 3,
    number: 3,
    title: "Doces & Guloseimas",
    content: [
      "2 Leite em Pó (400g)",
      "1 Leite Condensado (395g)",
      "1 Maionese (200g)",
      "1 Achocolatado (400g)",
      "1 Mistura para Bolo (450g)",
      "2 Milho para Pipoca (140g)",
      "1 Flocos de Cereal (300g)",
      "5 Biscoitos Recheado (125g)",
      "5 Biscoitos Aguá e sal (125g)",
      "5 Biscoitos Maisena (125g)",
      "4 Sucos em Pó (25g)",
      "2 Mine Toradas Salgadas (140g)",
      "1 Caixa de Bombom Garoto (500g)",
      "3 Creme de Leite (200g)",
      "2 Leite de Coco (200g)",
      "1 Leite em Pó Ninho (400g)",
      "1 Doce de Leite (400g)",
      "1 Doce em Caldas (400g)"
    ]
  }
]
